.cardStudy {
    height: 150px;
    width: 350px;
    box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2);
    border-radius: 20px;
    background-color: white;
    color: #181818;
    display: grid;
    grid-template-columns: 1fr 2fr;
    /* place-items: center; */
    align-items: center;
    gap : 1em;

    padding: 1em;
    box-sizing: border-box;
}

.cardStudy p, .cardStudy h2{
    color: #181818;
    margin: 0 !important;
}

.icon_study{
    height: 100%;
    width: 100%;

    /* box-shadow: 0 4px 8px 0 rgba(0,0,0,0.4); */
    filter: drop-shadow(0px 2px 4px rgba(0, 0, 0, 0.5));

    
    align-content: center;
}

.icon_study img{
    height: 100%;
    width: 100%;

    object-fit: contain;

    border-radius: 1em;
}

.text_study{
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}