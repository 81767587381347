#projects_cards {
    width: 100%;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    gap: 2em;
    box-sizing: border-box;
    padding: 1em 0;
}

@media screen and (max-width: 600px) {
    #projects_cards{
        display: flex;
        flex-direction: column;
        align-items: center;
    }
}