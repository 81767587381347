#presentation{
    /* width: 100%; */
    height: 100%;

    display: flex;
    /* grid-template-columns: 2fr 1fr; */
    /* justify-content: space-between; */
    gap: 2em;
    /* justify-content: center; */
    align-items: center;
}

#presentation h2{
    margin-bottom: 0;
}

#textPresentation{
    width: 500PX;
}

#photoProfil{
    height: 250px;
    width: 250px;

    position: relative;
}

.photoProfil{
    width: 100%;
    height: 100%;

    object-fit: cover;
    border: 2px solid #fffffe;
    border-radius: 100%;
}

.icone_photo_profil{
    position: absolute;

    height: 100px;
    width: 100px;

    object-fit: contain;
}

.aboutMe_links{
    display: flex;
    gap: 1em;
}

@keyframes myAnimation {
    from { transform: translateX(0); }
    to { transform: translateY(-400px); }
}

@media screen and (max-width: 600px) {
    #presentation{
        flex-direction: column-reverse;
    }

    #textPresentation{
        width: 80%;
    }
}