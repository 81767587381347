.isCollapsed {
    transition: transform 0.6s ease-in-out;
    transform: translateY(-88%); /* Move up */
}

.isVisible {
    transition: transform 0.6s ease-in-out;
    transform: translateY(0); /* Move down */
}

.card {
    height: 500px;
    width: 300px;
    box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2);
    border-radius: 20px;
    background-color: white;
    color: #181818;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    cursor: pointer;
}

.card_container{
    height: 100%;
    width: 100%;
}

.card p {
    color: #181818;
    margin: 14px;
}
.card h2 {
    color: #181818;
    margin: 0 !important;
}

.card_collapse{
    height: 100%;
    width: 100%;

    display: flex;
    justify-content: space-between;
    flex-direction: column;
}

.card_collapse img{
    object-fit: cover;
    justify-self: center;
    width: 100%;
    height: 100%;
}
.card_illustration{
    height: 450px;
}

.card_title{
    box-sizing: border-box;
    width: 100%;

    display: flex;
    justify-content: space-between;
    align-items: center;

    padding: 0 20px;
    /* margin-bottom: 1em; */

    font-size: 14px;
    border-bottom: 1px solid black;
    /*border-top: 1px solid #ff8906; */

    background-color: #fffffe;
}

.card_title p, .card_title h2, .card_title i{
    color: black;
}

.card_uncollapse{
    height: 80%;
    width: 100%;

    display: flex;
    /* justify-content: space-between; */
    flex-direction: column;
}

.card_uncollapse_container{
    box-sizing: border-box; /* assure que le padding est inclus dans la largeur de 100% */
    width: 100%;
    
    padding: 0 20px;

    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.card_uncollapse_container p{
    margin: 1em 0;
}

.card_count {
   /* width: 3em; */
   display: flex;
   align-items: center;
   /* justify-content: space-between; */
   gap: 8px;
}

.card_count p,
i {
   font-size: 24px;
}

.tags_container {
    display: flex;
    flex-wrap: wrap;
    margin-top: 1em;
    gap: 1em;
}

.card_links{
   display: flex;
   gap: 1em;
}

.card_links a{
    color: black;
}