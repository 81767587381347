.studies_container {
    width: 100%;
    display: flex;
    gap: 3em;
}

@media screen and (max-width: 600px) {
    .studies_container{
        flex-direction: column;
        align-items: center;
    }
}