body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, 'Nunito Sans','Segoe UI','sans-serif';
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    background-color: #0f0e17;
    overflow-x: hidden;
}

h1,h2,h3 {
   font-family: 'PT Sans','Roboto',sans-serif;
   font-weight: bold;
   color: #fffffe;
}

h2{
   margin-top: 8px;
}

p {
   font-family: 'Nunito Sans','Segoe UI','sans-serif';
   font-size: 16px;
   margin-top: 1em;
   margin-bottom: 1em;

   color: #fffffe;
}

a {
   font-size: 16px;
   margin-top: 1em;
   margin-bottom: 1em;

   color: #fffffe;

   position: relative;
   text-decoration: none;
   overflow: hidden;
}

a::after {
   content: '';
   position: absolute;
   bottom: 0;
   left: 0;
   width: 0;
   height: 1px;
   background: currentColor;
   transition: width .3s ease-in-out;
}

a:hover::after {
   width: 100%;
}

.icon {
   height: 36px;
   font-size: 36px;
}

.tag {
   width: auto;
   font-size: 14px;
   display: flex;
   justify-content: center;
   align-items: center;

   padding: 4px 8px;

   color: #fffffe;
   background-color: black;

   border: 1px solid #fffffe;
   border-radius: 2em;
}

main {
    width: 100vw;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

#content {
    width: 100%;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 0 20% 0 20%;
}

#content section{
    padding: 2em 0;
}

section {
    scroll-margin-top: 50px; /* Permet de positionner la section par rapport au header */
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: relative;
}

section::before {
    content: "";
    display: block;
    height: 150px; /* Hauteur de votre barre de navigation */
    margin-top: -150px; /* Négatif pour déplacer la cible de l'ancre vers le haut */
    visibility: hidden;
    pointer-events: none;
}  

section::after {
    content: "";
    display: block;
    position: absolute;
    bottom: 0;
    width: 100vw;
    height: 1px;
    background: #fffffe; 
}

.section_title{
   font-size: 50px;
}

.langageButton{
    height: 30px;
    width: 30px;

    box-sizing: border-box;
    display: flex;
    justify-content: center;
    align-items: center;

    background: none;
    border: 2px solid #fffffe;
    border-radius: 100%;
    padding: 2px;

    align-self: center;
    cursor: pointer;
}

.langageButton img{
    height: 100%;
    object-fit: cover;
}

.langageButton p{
    margin: 0;
}

.disable{
    visibility: hidden;
}
@media screen and (max-width: 600px) {
    main {
        width: 100%;
    }

    #content{
        padding: 0;
    }
}

/*
bg #0f0e17
Headline/ button text #fffffe
Paragraphe #a7a9be
Button #ff8906

stroke black
main #fffffe
highlihght #ff8906
secondary #f25f4c
tertiary #e53170
*/