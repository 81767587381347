header {
    height: 50px;
    width: 100%;

    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 1em;

    position: sticky;
    top: 0;
    z-index: 100;

    background-color: #0f0e17;
    border-bottom: 1px solid #fffffe;
}

header h1 {
    color: #fffffe;
}

.header_left {
    display: flex;
}

#openNav {
    display: none;
}

nav {
    display: flex;
    gap: 1em;
    margin-right: 3em;
}


@media screen and (max-width: 600px) {
    header {
        position: sticky;
        box-sizing: border-box;
    }

    .header_left {
        width: 100%;
        justify-content: space-between;
        align-items: center;
    }

    #openNav {
        display: block;
        width: 40px;
        height: 40px;
        text-align: center;
        padding: 0;
        background-color: #fffffe;
        border: none;
        color: #181818;
    }

    nav {
        background-color: #0f0e17;
        border: 2px solid #fffffe;
        border-top: none;
        padding: 1em 2em 1em 10px;
        position: absolute;
        top: 0;
        right: 0;
        margin: 0;

        display: flex;
        flex-direction: column;
        align-items: flex-end;
        justify-content: center;
        border-radius: 0 0 0 1em;
    }

    .langageButton{
        align-self: auto;
    }
}