.skills_container {
    width: 100%;
    display: flex;
    justify-content: center;
    gap: 3em;
}

@media screen and (max-width: 600px) {
    .skills_container{
        width: 80%;
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        justify-content: center;
        align-items: center;
    }
}