.cardSkill {
    height: 130px;
    width: 130px;
    box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2);
    border-radius: 20px;
    background-color: white;
    color: #181818;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;

    padding: 1em;
    box-sizing: border-box;
}

.cardSkill p, .cardSkill h2{
    color: #181818;
    margin: 0 !important;
}

.icon_skill{
    height: 64px;
    width: 64px;

    filter: drop-shadow(0px 2px 4px rgba(0, 0, 0, 0.5));

    align-content: center;
}

.icon_skill img{
    height: 100%;
    width: 100%;

    object-fit: cover;
}

.text_skill{
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    white-space: nowrap;
}

@media screen and (max-width: 600px) {
    .cardSkill{
        width: 100px;
    }
}