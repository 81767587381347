#welcome {
    height: calc(100vh - 80px);
    width: 100vw;
    margin-top: 0;

    position: relative;
}

#backgroundImage {
    width: 100%;
    height: 100%;
    overflow: hidden;
}

#backgroundImage img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;
    /* Cela centre l'image dans la div */
}

#welcomeTite {
    position: absolute;
    top: 30%;
    left: 50%;
    transform: translate(-50%, -50%);

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;

    text-shadow: 0px 0px 10px #181818;
}

#welcomeTite h1 {
    font-size: 100px;
    margin: 5px;
    letter-spacing: 5px;
}

#welcomeTite p {
    font-size: 30px;
    margin: 0;
}

/* Auto write  */
#claim-container {
    font-family: sans-serif;
    margin: 1em 0;
    font-weight: bold;
    line-height: 0.85;
    word-wrap: break-word;
}

#claim {
    letter-spacing: -0.06em;
    font-size: 1.5em;
    color: white;
}


#claim .off {
    visibility: hidden;
}

#hidden_bg {
    display: none;
}

@media screen and (max-width: 600px) {
    #welcomeTite {
        top: 40%;
    }
}