form{
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 500px;
}

form > * {
    width: 100%;
}

input{
    border-radius: 1em;
    height: 2em;
    padding: 8px;
}

textarea{
    border-radius: 1em;
    height: 200px;
    padding: 8px;
}

button{
    width: 150px;
    padding: 1em;
    border-radius: 8px;
    background-color: #ff8906;
    color: #fffffe;
}

button[type="submit"]{
    margin-top: 1em;

    transition: color 0.2s ease-in-out;
    transition: border 0.2s ease-in-out;
    transition: background-color 0.2s ease-in-out;
}

button[type="submit"]:hover{
    cursor: pointer;
    margin-top: 1em;
    color: #ff8906;
    border: 2px solid #ff8906;
    background-color: #fffffe;
}

input, button, textarea {
    font-family: 'Nunito Sans','Segoe UI','sans-serif';
    font-size: 16px;
    color: black;
}